import styled from "styled-components";
import {Section} from "../../partials";
import {animations, colors, fonts} from "../../../config";
import {Col, Row} from "styled-bootstrap-grid";
import {Link} from "gatsby-plugin-react-i18next";
import { GeneralTitle } from "../../generalStyles";

export const StyledSection = styled(Section)`
  background-color: rgba(36,36,36,0);
  background-image: linear-gradient(90deg, #040404 0%,#171718 100%);
  background-position: center top;
  background-repeat: no-repeat;
`;

export const StyledRow = styled(Row)`
  margin-bottom: 50px;
  
  &:last-child {
    margin-bottom: 0;
  }
`;

export const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;  
  min-height: 490px;
  
  @media screen and (max-width: 992px) {
    min-height: 335px;
  }
`;

export const StyledTitle = styled(GeneralTitle)`
  margin-bottom: 15px;
`;

export const StyledText = styled.p`
  font-family: ${fonts.roboto};
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
  color: #ffffff;
  max-width: 475px;

  @media screen and (max-width: 576px) {
    font-size: 16px;
  }
`;

export const Button = styled(Link)`
  display: block;
  text-decoration: none;
  text-align: center;
  border: 1px solid ${colors.orange};
  transition: ${animations.default};
  color: #ffffff;
  margin-top: 32px;
  width: 255px;
  padding: 13px 29px;
  font-size: 14px;
  font-family: ${fonts.robotoCondensed};
  background-color: transparent;
  
  &:hover {
    cursor: pointer;
    color: ${colors.orange};
  }
`;

export const ImgWrapper = styled.div`
  max-width: 480px;
  
  @media screen and (max-width: 768px) {
    min-height: 485px;
  }

  @media screen and (max-width: 576px) {
    min-height: 350px;
  }
`;

export const StyledImg = styled.img`
  width: 100%;
`;