import React from "react";
import {Container} from "styled-bootstrap-grid";
import * as P from './parts';
import * as A from '../../../assets/images';
import {Trans} from "react-i18next";
import Fade from "react-reveal/Fade";
import { useI18next } from "gatsby-plugin-react-i18next";

const SectionWork = () => {
    const {language} = useI18next();

    return (
        <P.StyledSection>
            <Container>
                <Fade>
                    <P.StyledTitle>
                        <Trans>how_does_mobd_work</Trans>
                    </P.StyledTitle>
                    <P.StyledText>
                        <Trans>mobd_is_technologically_advanced_service</Trans>
                    </P.StyledText>
                    <P.StyledText>
                        <Trans>effortless_installation</Trans>
                    </P.StyledText>
                    <P.StyledText>
                        <Trans>join_mobd</Trans>
                    </P.StyledText>
                    <P.ImgWrapper>
                        {
                            language === 'pl' ?
                              <P.StyledImg src={A.infografikaPl} alt={'MOBD guide'} /> :
                              <P.StyledImg src={A.infografika} alt={'MOBD guide'} />
                        }
                    </P.ImgWrapper>
                </Fade>
            </Container>
        </P.StyledSection>
    )
}

export default SectionWork;