import React from 'react';
import * as P from './parts';
import * as A from '../../../assets/images';
import {Container} from "styled-bootstrap-grid";
import {Trans} from "react-i18next";
import Fade from "react-reveal/Fade";

const SectionElements = () => {
    return (
      <P.StyledSection>
          <Container>
              <Fade cascade>
                  <P.StyledSuptitle>
                      <Trans>service_elements</Trans>
                  </P.StyledSuptitle>
                  <P.StyledTitle>
                      <Trans>mobd_device</Trans>
                  </P.StyledTitle>
              </Fade>
              <Fade>
                  <P.StyledRow>
                      <P.StyledCol align={"right"}>
                          <P.StyledItem>
                              <P.StyledItemHeader content={"right"}>
                                  <P.StyledItemTitle>
                                      <Trans>4g_and_5g</Trans>
                                  </P.StyledItemTitle>
                                  <P.StyledItemImg src={A.lte} alt={"4G & 5G"} />
                              </P.StyledItemHeader>
                              <P.StyledItemText>
                                  <Trans>4g_network</Trans>
                              </P.StyledItemText>
                          </P.StyledItem>
                          <P.StyledItem expOffset>
                              <P.StyledItemHeader content={"right"}>
                                  <P.StyledItemTitle>
                                      <Trans>sim-less</Trans>
                                  </P.StyledItemTitle>
                                  <P.StyledItemImg src={A.chip} alt={"Sim-less chip"} />
                              </P.StyledItemHeader>
                              <P.StyledItemText>
                                  <Trans>built-in_sim</Trans>
                              </P.StyledItemText>
                          </P.StyledItem>
                          <P.StyledItem expOffset>
                              <P.StyledItemHeader content={"right"}>
                                  <P.StyledItemTitle>
                                      <Trans>bluetooth</Trans>
                                  </P.StyledItemTitle>
                                  <P.StyledItemImg src={A.bluetooth} alt={"Bluetooth"} />
                              </P.StyledItemHeader>
                              <P.StyledItemText>
                                  <Trans>ble_connection</Trans>
                              </P.StyledItemText>
                          </P.StyledItem>
                      </P.StyledCol>
                      <P.StyledCol>
                          <P.ImgWrapper>
                              <P.MainImg src={A.deviceTransparent} />
                          </P.ImgWrapper>
                      </P.StyledCol>
                      <P.StyledCol>
                          <P.StyledItem>
                              <P.StyledItemHeader>
                                  <P.StyledItemImg src={A.gyroscope} alt={"Acc & Gyro"} />
                                  <P.StyledItemTitle>
                                      <Trans>acc_and_gyro</Trans>
                                  </P.StyledItemTitle>
                              </P.StyledItemHeader>
                              <P.StyledItemText>
                                  <Trans>built-in_accelerometer_and_gyro</Trans>
                              </P.StyledItemText>
                          </P.StyledItem>
                          <P.StyledItem expOffset>
                              <P.StyledItemHeader>
                                  <P.StyledItemImg src={A.cloud} alt={"Cloud Sync"} />
                                  <P.StyledItemTitle>
                                      <Trans>cloud_sync</Trans>
                                  </P.StyledItemTitle>
                              </P.StyledItemHeader>
                              <P.StyledItemText>
                                  <Trans>on-the-fly_sync</Trans>
                              </P.StyledItemText>
                          </P.StyledItem>
                          <P.StyledItem expOffset>
                              <P.StyledItemHeader>
                                  <P.StyledItemImg src={A.diagnostic} alt={"OBD2 data"} />
                                  <P.StyledItemTitle>
                                      <Trans>obd2_data</Trans>
                                  </P.StyledItemTitle>
                              </P.StyledItemHeader>
                              <P.StyledItemText>
                                  <Trans>can_bus_data</Trans>
                              </P.StyledItemText>
                          </P.StyledItem>
                      </P.StyledCol>
                  </P.StyledRow>
              </Fade>
              <Fade>
                  <P.Description>
                      <Trans>mobd_is_a_obd2_reader</Trans>
                  </P.Description>
              </Fade>
          </Container>
      </P.StyledSection>
    );
}

export default SectionElements;