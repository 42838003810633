import * as React from "react"
import Page from "../components/Page";
import {Copyright, Footer, Nav, Partners} from "../components/partials";
import {
    SectionElements,
    SectionMobd,
    SectionMore,
    SectionPricing,
    SectionWork
} from "../components/home";
import SEO from "../components/utils/SEO";
import {graphql} from "gatsby";

const IndexPage = () => (
    <Page>
        <SEO />
        <Nav/>
        <SectionMobd/>
        <SectionMore/>
        <SectionElements/>
        <SectionWork/>
        <SectionPricing/>
        <Footer />
        <Partners isHomepage={true}/>
        <Copyright/>
    </Page>
);

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;