import styled from "styled-components";
import {Section} from "../../partials";
import {fonts, colors} from "../../../config";
import { GeneralTitle } from "../../generalStyles";

export const StyledSection = styled(Section)`
  @media screen and (max-width: 576px) {
    padding-top: 0;
  }
`;

export const StyledSuptitle = styled(GeneralTitle)`
  
  @media screen and (max-width: 1200px) {
    text-align: left;
  }

  @media screen and (max-width: 992px) {
    text-align: center;
  }
`;

export const StyledTitle = styled.h2`
  font-size: 58px;
  font-family: ${fonts.roboto};
  text-align: center;
  margin: 45px 0 70px;
  color: #ffffff;
  line-height: 1.16;

  @media screen and (max-width: 1200px) {
    text-align: left;
  }

  @media screen and (max-width: 992px) {
    margin: 40px 0 40px;
    text-align: center;
  }
  
  @media screen and (max-width: 576px) {
    font-size: 40px;
    margin: 30px 0 40px;
  }
`;

export const Description = styled.p`
  color: ${colors.white};
  font-size: 16px;
  line-height: 1.6;
  font-weight: 300;
  font-family: ${fonts.nunitoSans};
  text-align: center;
  max-width: 940px;
  margin: 0 auto;
  padding: 0 15px;

  @media screen and (max-width: 1200px) {
    max-width: unset;
    margin: unset;
  }
  
  @media screen and (max-width: 768px) {
    margin-top: 30px;
  }
`;

export const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 15px;
  margin-top: 55px;
  min-height: 450px;
  
  @media screen and (max-width: 1200px) {
    min-height: 375px;
    margin-top: 40px;
  }
`;

export const StyledCol = styled.div`
  text-align: ${({align}) => align ? align : 'left'};
  display: flex;
  flex-direction: column;
  align-items: ${({align}) => align === 'right' ? 'end' : 'start'};
  width: 33.33%;

  @media screen and (max-width: 1200px) {
    &:nth-child(1) {
      order: 1;
    }
    &:last-child {
      order: 2;
    }
  }
  
  @media screen and (max-width: 992px) {
    width: 50%;
    margin-top: 12px;
    
    &:nth-child(2) {
      width: 100%;
    }
  }
  
  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    text-align: left !important;
    align-items: center !important;
  }
`;

export const StyledItem = styled.div`
  margin: ${({expOffset}) => expOffset ? '28px 20px 8px' : '15px 20px 8px'};
  max-width: 300px;
  height: 95px;
  
  &:first-child {
    margin: 15px 20px 8px;
  }
  
  @media screen and (max-width: 1200px) {
    margin: ${({expOffset}) => expOffset ? '15px 20px 8px' : '5px 20px 8px'};
    max-width: 260px;
    height: 105px;
    
    &:first-child {
      margin: 5px 20px 8px;
      height: 95px;
    }
  }

  @media screen and (max-width: 768px) {
    height: unset;
    max-width: unset;
    width: 100%;
    margin: 15px 20px 8px;
    
    &:first-child {
      height: unset;
      margin: 15px 20px 8px;
    }
  }
`;

export const StyledItemHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({content}) => content ? content : 'flex-start'};
  
  @media screen and (max-width: 768px) {
    width: 100%;
    justify-content: center;
  }
`;

export const StyledItemTitle = styled.h5`
  font-size: 26px;
  font-family: ${fonts.nunitoSans};
  line-height: 1.2;
  font-weight: 600;
  color: #ffffff;
  margin: 0 15px;
`;

export const StyledItemImg = styled.img`
  width: 26px;
  height: 26px;
  
  @media screen and (max-width: 768px) {
    order: -1;
  }
`;

export const StyledItemText = styled.p`
  font-size: 16px;
  line-height: 1.6;
  font-weight: 300;
  font-family: ${fonts.nunitoSans};
  color: ${colors.demiWhite};
  display: block;
  margin-top: 10px;
  
  @media screen and (max-width: 768px) {
    text-align: center;
  }
`;

export const ImgWrapper = styled.div`
  max-width: 240px;
  margin: 0 auto;
  
  @media screen and (max-width: 1200px) {
    max-width: 220px;
  }
  
  @media screen and (max-width: 992px) {
    margin-bottom: 30px;
  }

  @media screen and (max-width: 768px) {
    max-width: 200px;
  }
`;

export const MainImg = styled.img`
  width: 100%;
  
  @media screen and (max-width: 992px) {
    width: auto;
    min-height: 370px;
  }

  @media screen and (max-width: 768px) {
    width: auto;
    min-height: 295px;
  }
`;