import React from 'react';
import {Col, Container} from "styled-bootstrap-grid";
import * as P from './parts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import {Trans} from "react-i18next";
import Fade from "react-reveal/Fade";

const SectionMore = () => {
    return (
        <P.StyledSection id={'learn-more'}>
            <Container>
                <Fade>
                    <P.StyledTitle>
                        <Trans>mobd_is_for_everyone</Trans>
                    </P.StyledTitle>
                </Fade>
                <Fade>
                    <P.StyledRow>
                        <Col lg={4}>
                            <P.StyledItem>
                                <P.StyledItemTitle>
                                    <Trans>business_fleet</Trans>
                                </P.StyledItemTitle>
                                <P.StyledItemText>
                                    <Trans>advanced_data_analytics</Trans>
                                </P.StyledItemText>
                                <P.StyledItemLink to={'/fleet-managers'}>
                                    <P.StyledItemLinkText>
                                        <Trans>read_details</Trans>
                                    </P.StyledItemLinkText>
                                    <FontAwesomeIcon icon={faArrowRight}/>
                                </P.StyledItemLink>
                            </P.StyledItem>
                        </Col>
                        <Col lg={4}>
                            <P.StyledItem>
                                <P.StyledItemTitle>
                                    <Trans>personal_individual</Trans>
                                </P.StyledItemTitle>
                                <P.StyledItemText>
                                    <Trans>record_your_trips</Trans>
                                </P.StyledItemText>
                                <P.StyledItemLink to={'/individuals'}>
                                    <P.StyledItemLinkText>
                                        <Trans>read_details</Trans>
                                    </P.StyledItemLinkText>
                                    <FontAwesomeIcon icon={faArrowRight}/>
                                </P.StyledItemLink>
                            </P.StyledItem>
                        </Col>
                        <Col lg={4}>
                            <P.StyledItem>
                                <P.StyledItemTitle>
                                    <Trans>business_custom</Trans>
                                </P.StyledItemTitle>
                                <P.StyledItemText>
                                    <Trans>custom_solutions</Trans>
                                </P.StyledItemText>
                                <P.StyledItemLink to={'/custom'}>
                                    <P.StyledItemLinkText>
                                        <Trans>read_details</Trans>
                                    </P.StyledItemLinkText>
                                    <FontAwesomeIcon icon={faArrowRight}/>
                                </P.StyledItemLink>
                            </P.StyledItem>
                        </Col>
                    </P.StyledRow>
                </Fade>
            </Container>
        </P.StyledSection>
    );
}

export default SectionMore;