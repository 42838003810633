import styled from "styled-components";
import {Section} from "../../partials";
import {colors, fonts} from "../../../config";
import {Row} from "styled-bootstrap-grid";
import {Link} from "gatsby-plugin-react-i18next";
import { GeneralTitle } from "../../generalStyles";

export const StyledSection = styled(Section)`
  padding: 130px 0 30px;
  margin-top: -100px;

  @media screen and (min-width: 768px) {
    padding: 140px 0 40px;
  }

  @media screen and (min-width: 1200px) {
    padding: 170px 0 70px;
  }
  
`;

export const StyledTitle = styled(GeneralTitle)`
  margin-bottom: 15px;

  @media screen and (max-width: 992px) {
    text-align: center;
  }
`;

export const StyledRow = styled(Row)`
  margin-top: 50px;
  
  @media screen and (max-width: 576px) {
    margin-top: 35px;
  }
`;

export const StyledItem = styled.div`
  position: relative;
  max-width: 335px;
  height: 100%;
  padding-bottom: 40px;
  
  @media screen and (max-width: 992px) {
    max-width: unset;
    padding-bottom: 0;
    margin-bottom: 40px;
  }
`;

export const StyledItemTitle = styled.h4`
  font-family: ${fonts.nunitoSans};
  font-weight: 600;
  font-size: 24px;
  color: #ffffff;
  line-height: 1.2;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(167,167,180,0.43);
  
  @media screen and (max-width: 576px) {
    font-size: 22px;
  }
`;

export const StyledItemText = styled.p`
  padding-top: 15px;
  font-size: 16px;
  color: ${colors.demiWhite};
  font-family: ${fonts.nunitoSans};
  line-height: 1.6;
  font-weight: 300;
`;

export const StyledItemLink = styled(Link)`
  position: absolute;
  left: 0;
  bottom: 0;
  color: ${colors.orange};
  font-size: 16px;
  display: flex;
  align-items: center;
  text-decoration: none;

  @media screen and (max-width: 992px) {
    position: static;
    margin-top: 20px;
  }
`;

export const StyledItemLinkText = styled.p`
  font-family: ${fonts.nunitoSans};
  margin-right: 10px;
`;