import React from "react";
import {Col, Container} from "styled-bootstrap-grid";
import * as P from './parts';
import * as A from '../../../assets/images';
import {Trans} from "react-i18next";
import Fade from "react-reveal/Fade";

const SectionMobd = () => {
    return (
        <P.StyledSection>
            <Container>
                <P.StyledRow>
                    <Col lg={6}>
                        <P.Content>
                            <Fade>
                                <P.StyledTitle>
                                    <Trans>modern_fleet_management</Trans>
                                </P.StyledTitle>
                                <P.StyledText>
                                    <Trans>device_description</Trans>
                                </P.StyledText>
                            </Fade>
                            <Fade>
                                <P.StyledButton to={'/#learn-more'}>
                                    <Trans>read_more_btn</Trans>
                                </P.StyledButton>
                            </Fade>
                        </P.Content>
                    </Col>
                    <Col lg={6}>
                        <Fade>
                            <P.StyledImg src={A.adminPanelImg} alt={'Admin panel'}/>
                        </Fade>
                    </Col>
                </P.StyledRow>
            </Container>
        </P.StyledSection>
    );
}

export default SectionMobd;