import styled from "styled-components";
import { Section } from "../../partials";
import {animations, colors, fonts} from "../../../config";
import {Row} from "styled-bootstrap-grid";
import {Link} from "gatsby-plugin-react-i18next";

export const StyledSection = styled(Section)`
  padding: 100px 0 0;

  @media screen and (min-width: 576px) {
    padding: 120px 0 0;
  }

  @media screen and (min-width: 768px) {
    padding: 140px 0 0;
  }

  @media screen and (min-width: 1200px) {
    padding: 70px 0 0;
  }
`;

export const StyledRow = styled(Row)`
  align-items: center;
`;

export const Content = styled.div`
  min-height: 630px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  @media screen and (max-width: 1366px) {
    min-height: 610px;
  }

  @media screen and (max-width: 1200px) {
    min-height: 500px;
  }

  @media screen and (max-width: 992px) {
    min-height: unset;
  }
`;

export const StyledTitle = styled.h1`
  font-size: 58px;
  font-family: ${fonts.roboto};
  line-height: 1.16;
  color: ${colors.white};
  margin-bottom: 25px;
  
  @media screen and (max-width: 1200px) {
    font-size: 56px;
  }
  
  @media screen and (max-width: 992px) {
    text-align: center;
  }
  
  @media screen and (max-width: 576px) {
    font-size: 42px;
  }
`;

export const StyledText = styled.p`
  color: ${colors.white};
  font-size: 16px;
  font-weight: 300;
  font-family: ${fonts.nunitoSans};
  line-height: 1.6;

  @media screen and (max-width: 992px) {
    text-align: center;
  }
`;

export const StyledButton = styled(Link)`
  padding: 13px 0;
  width: 255px;
  line-height: 17px;
  text-decoration: none;
  display: block;
  font-size: 14px;
  font-family: ${fonts.robotoCondensed};
  color: ${colors.white};
  border: 1px solid ${colors.orange};
  text-align: center;
  margin-top: 40px;
  background-color: transparent;
  transition: ${animations.default};
  
  &:hover {
    color: ${colors.orange};
    cursor: pointer;
  }
  
  @media screen and (max-width: 992px) {
    margin: 40px auto 0;
  }
`;

export const StyledImg = styled.img`
  min-width: 703px;
  max-width: 703px;
  
  @media screen and (max-width: 1366px) {
    max-width: 675px;
    min-width: 675px;
  }
  
  @media screen and (max-width: 1200px) {
    min-width: 49vw;
    max-width: 49vw;
  }
  
  @media screen and (max-width: 992px) {
    max-width: unset;
    min-width: unset;
    height: 400px;
    width: auto;
    margin: 0 auto;
    display: block;
  }
  
  @media screen and (max-width: 576px) {
    height: 300px;
  }
  
  @media screen and (max-width: 360px) {
    height: 285px;
  }

  @media screen and (max-width: 340px) {
    height: 250px;
  }
`;