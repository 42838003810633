import React from "react";
import {Container} from "styled-bootstrap-grid";
import * as P from './parts';
import * as A from '../../../assets/images';
import {Trans} from "react-i18next";
import Fade from "react-reveal/Fade";
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import ListItem from "./ListItem/ListItem";
import {useWindowSize} from "../../../helpers/hooks";
import { useI18next } from "gatsby-plugin-react-i18next";


const SectionPricing = () => {
    const {width} = useWindowSize();
    const {language} = useI18next();

    return (
        <P.StyledSection id={'pricing'}>
            <Container>
                <Fade>
                    <P.StyledTitle>
                        <Trans>pricing</Trans>
                    </P.StyledTitle>
                </Fade>
                <Fade>
                    <P.Device>
                        <P.DeviceUnlicensedPrice>
                            <P.DevicePriceValue unsetWidth={true}>
                                {language === 'en' && <Trans>specific_pricing_fleet_price_currency</Trans>}
                                <Trans>specific_pricing_fleet_price_unit</Trans>
                                {language === 'pl' && width <= 768 && <>&nbsp;<Trans>specific_pricing_fleet_price_currency</Trans></>}
                            </P.DevicePriceValue>
                            <P.DevicePriceBlock>
                                <P.DevicePriceSup>
                                    {language === 'pl' && width > 768 &&
                                      <P.DevicePriceSupCurrency>
                                          <Trans>specific_pricing_fleet_price_currency</Trans>
                                      </P.DevicePriceSupCurrency>
                                    }
                                    <Trans>specific_price_general_unit_10_more_part_1</Trans>
                                </P.DevicePriceSup>
                                <P.DevicePriceSub>
                                    <P.DevicePriceSubOrange>
                                        <Trans>specific_price_general_unit_10_more_part_2</Trans>
                                    </P.DevicePriceSubOrange>
                                    <Trans>specific_price_general_unit_10_more_part_3</Trans>
                                </P.DevicePriceSub>
                            </P.DevicePriceBlock>
                        </P.DeviceUnlicensedPrice>
                        <P.DeviceHeader>
                            <P.DeviceImg src={A.deviceTransparentSmall}/>
                            <P.DeviceTitle>
                                <Trans>specific_price_general_device</Trans>
                            </P.DeviceTitle>
                            <P.DeviceSubtitle>
                                <Trans>specific_price_general_telematics_device</Trans>
                            </P.DeviceSubtitle>
                        </P.DeviceHeader>
                        <P.DeviceBottom>
                            <P.DeviceUnlicensedListTitle>
                                <Trans>specific_price_general_unlicensed_hardware</Trans>
                            </P.DeviceUnlicensedListTitle>
                            <P.DeviceListsContainer>
                                <P.DeviceUnlicensedList>
                                    <ListItem text={'specific_price_general_connectivity_via_mobile'}/>
                                    <ListItem text={'specific_price_general_one_user_per_device'}/>
                                    <ListItem text={'specific_price_general_trips_log'}/>
                                    <ListItem text={'specific_price_general_driver_score'}/>
                                </P.DeviceUnlicensedList>
                                <P.DeviceUnlicensedList>
                                    <ListItem text={'specific_price_general_dtc_alert'}/>
                                    <ListItem text={'specific_price_general_hud'}/>
                                    <ListItem text={'specific_price_general_vehicle_timeline'}/>
                                    <ListItem text={'specific_price_general_manual_firmware'}/>
                                </P.DeviceUnlicensedList>
                            </P.DeviceListsContainer>
                            <P.DeviceLink href={'request-a-demo'}>
                                <Trans>specific_pricing_general_buy_now</Trans>
                            </P.DeviceLink>
                        </P.DeviceBottom>
                    </P.Device>
                </Fade>
                <Fade>
                    <P.LicenseText>
                        <Trans>specific_pricing_general_add_license</Trans>
                    </P.LicenseText>
                    <P.LicenseArrow icon={faChevronDown}/>
                </Fade>
                <Fade cascade>
                    <P.StyledRow>
                        <P.StyledCol md={6}>
                            <P.Device>
                                <P.DevicePriceTop>
                                    <P.DevicePriceValueTop expanded={language === 'en'}>
                                        {language === 'en' && <Trans>specific_pricing_individuals_price_per_device_annually_currency</Trans>}
                                        <Trans>specific_pricing_individuals_price_per_device_annually</Trans>
                                        {language === 'pl' && width <= 992 && <>&nbsp;<Trans>specific_pricing_individuals_price_per_device_annually_currency</Trans></>}
                                    </P.DevicePriceValueTop>
                                    <P.DevicePriceText>
                                        {language === 'pl' && width > 992 &&
                                            <P.DevicePriceTextOrange>
                                                <Trans>specific_pricing_individuals_price_per_device_annually_currency</Trans>
                                            </P.DevicePriceTextOrange>
                                        }
                                        <Trans>specific_price_general_annually</Trans>
                                    </P.DevicePriceText>
                                </P.DevicePriceTop>
                                <P.DevicePriceBottom>
                                    <P.DevicePriceValueBottom expanded={language === 'en'}>
                                        {language === 'en' && <Trans>specific_pricing_individuals_price_per_device_monthly_currency</Trans>}
                                        <Trans>specific_pricing_individuals_price_per_device_monthly</Trans>
                                        {language === 'pl' && width <= 992 && <>&nbsp;<Trans>specific_pricing_individuals_price_per_device_monthly_currency</Trans></>}
                                    </P.DevicePriceValueBottom>
                                    <P.DevicePriceTextBottom>
                                        {language === 'pl' && width > 992 &&
                                            <P.DevicePriceTextWhite>
                                                <Trans>specific_pricing_individuals_price_per_device_monthly_currency</Trans>
                                            </P.DevicePriceTextWhite>
                                        }
                                        <Trans>specific_price_general_month</Trans>
                                    </P.DevicePriceTextBottom>
                                </P.DevicePriceBottom>
                                <P.DeviceHeader>
                                    <P.DeviceTitle>
                                        <Trans>specific_price_individual_title</Trans>
                                    </P.DeviceTitle>
                                    <P.DeviceSubtitle>
                                        <Trans>specific_price_individual_individual_license</Trans>
                                    </P.DeviceSubtitle>
                                </P.DeviceHeader>
                                <P.DeviceBottom>
                                    <P.DeviceDescription>
                                        <Trans>specific_pricing_general_global_connectivity</Trans>
                                    </P.DeviceDescription>
                                    <P.DeviceListTitle>
                                        <Trans>specific_price_general_mobile_platforms</Trans>
                                    </P.DeviceListTitle>
                                    <P.DeviceList>
                                        <ListItem text={'specific_price_general_unlimited_users'}/>
                                        <ListItem text={'specific_price_general_trips_log'}/>
                                        <ListItem text={'specific_price_general_driver_score_short'}/>
                                        <ListItem text={'specific_price_general_early_diagnostics'}/>
                                        <ListItem text={'specific_price_general_accident_detection'}/>
                                        <ListItem text={'specific_price_general_expense_management'}/>
                                        <ListItem text={'specific_price_general_hud'}/>
                                        <ListItem text={'specific_price_general_vehicle_timeline'}/>
                                    </P.DeviceList>
                                    <P.DeviceListTitle>
                                        <Trans>specific_price_general_additional_features</Trans>
                                    </P.DeviceListTitle>
                                    <P.DeviceList>
                                        <ListItem text={'specific_price_general_firmware_updates'}/>
                                        <ListItem text={'specific_price_general_premium_support'}/>
                                    </P.DeviceList>
                                    <P.DeviceLink href={'request-a-demo'}>
                                        <Trans>specific_pricing_general_buy_now</Trans>
                                    </P.DeviceLink>
                                </P.DeviceBottom>
                            </P.Device>
                            { width > 767 &&
                                <P.Device>
                                    <P.DeviceHeader>
                                        <P.DeviceTitle>
                                            <Trans>specific_price_custom_title</Trans>
                                        </P.DeviceTitle>
                                    </P.DeviceHeader>
                                    <P.DeviceBottom>
                                        <P.DeviceSubtitle>
                                            <Trans>specific_price_custom_talk_to_experts</Trans>
                                        </P.DeviceSubtitle>
                                        <P.DeviceLink href={'mailto:sales@mobd.pl'}>
                                            <Trans>custom_contact</Trans>
                                        </P.DeviceLink>
                                    </P.DeviceBottom>
                                </P.Device>
                            }
                        </P.StyledCol>
                        <P.StyledCol md={6}>
                            <P.Device>
                                <P.DevicePriceTop>
                                    <P.DevicePriceValueTop expanded={language === 'en'}>
                                        {language === 'en' && <Trans>specific_pricing_fleet_price_per_device_annually_currency</Trans>}
                                        <Trans>specific_pricing_fleet_price_per_device_annually</Trans>
                                        {language === 'pl' && width <= 992 && <>&nbsp;<Trans>specific_pricing_fleet_price_per_device_annually_currency</Trans></>}
                                    </P.DevicePriceValueTop>
                                    <P.DevicePriceText>
                                        {language === 'pl' && width > 992 &&
                                          <P.DevicePriceTextOrange>
                                              <Trans>specific_pricing_fleet_price_per_device_annually_currency</Trans>
                                          </P.DevicePriceTextOrange>
                                        }
                                        <Trans>specific_price_general_annually</Trans>
                                    </P.DevicePriceText>
                                </P.DevicePriceTop>
                                <P.DevicePriceBottom>
                                    <P.DevicePriceValueBottom expanded={language === 'en'}>
                                        {language === 'en' && <Trans>specific_pricing_fleet_price_per_device_monthly_currency</Trans>}
                                        <Trans>specific_pricing_fleet_price_per_device_monthly</Trans>
                                        {language === 'pl' && width <= 992 && <>&nbsp;<Trans>specific_pricing_fleet_price_per_device_monthly_currency</Trans></>}
                                    </P.DevicePriceValueBottom>
                                    <P.DevicePriceTextBottom>
                                        {language === 'pl' && width > 992 &&
                                          <P.DevicePriceTextWhite>
                                              <Trans>specific_pricing_fleet_price_per_device_monthly_currency</Trans>
                                          </P.DevicePriceTextWhite>
                                        }
                                        <Trans>specific_price_general_month</Trans>
                                    </P.DevicePriceTextBottom>
                                </P.DevicePriceBottom>
                                <P.DeviceHeader>
                                    <P.DeviceTitle>
                                        <Trans>specific_price_fleet_title</Trans>
                                    </P.DeviceTitle>
                                    <P.DeviceSubtitle>
                                        <Trans>specific_price_fleet_fleet_license</Trans>
                                    </P.DeviceSubtitle>
                                </P.DeviceHeader>
                                <P.DeviceBottom>
                                    <P.DeviceDescription>
                                        <Trans>specific_pricing_general_global_connectivity</Trans>
                                    </P.DeviceDescription>
                                    <P.DeviceListTitle>
                                        <Trans>specific_price_general_mobd_fleet_dashboard</Trans>
                                    </P.DeviceListTitle>
                                    <P.DeviceList>
                                        <ListItem text={'specific_price_general_unlimited_users'}/>
                                        <ListItem text={'specific_price_general_trips_log'}/>
                                        <ListItem text={'specific_price_general_intelligent_analytics'}/>
                                        <ListItem text={'specific_price_general_early_diagnostics'}/>
                                        <ListItem text={'specific_price_general_accident_detection'}/>
                                        <ListItem text={'specific_price_general_expense_management'}/>
                                        <ListItem text={'specific_price_general_vehicle_timeline'}/>
                                        <ListItem text={'specific_price_general_roles_and_permissions'}/>
                                        <ListItem text={'specific_price_general_secured_api'}/>
                                    </P.DeviceList>
                                    <P.DeviceListTitle>
                                        <Trans>specific_price_general_mobile_platforms</Trans>
                                    </P.DeviceListTitle>
                                    <P.DeviceList>
                                        <ListItem text={'specific_price_general_trips_log'}/>
                                        <ListItem text={'specific_price_general_driver_score_exp'}/>
                                        <ListItem text={'specific_price_general_early_diagnostics'}/>
                                        <ListItem text={'specific_price_general_accident_detection'}/>
                                        <ListItem text={'specific_price_general_expense_management'}/>
                                        <ListItem text={'specific_price_general_hud'}/>
                                        <ListItem text={'specific_price_general_vehicle_timeline'}/>
                                        <ListItem text={'specific_price_general_role_based_access'}/>
                                    </P.DeviceList>
                                    <P.DeviceListTitle>
                                        <Trans>specific_price_general_additional_features</Trans>
                                    </P.DeviceListTitle>
                                    <P.DeviceList>
                                        <ListItem text={'specific_price_general_firmware_updates'}/>
                                        <ListItem text={'specific_price_general_premium_support'}/>
                                    </P.DeviceList>
                                    <P.DeviceLink href={'request-a-demo'}>
                                        <Trans>specific_pricing_general_buy_now</Trans>
                                    </P.DeviceLink>
                                </P.DeviceBottom>
                            </P.Device>
                            { width <= 767 &&
                                <P.Device>
                                    <P.DeviceHeader>
                                        <P.DeviceTitle>
                                            <Trans>specific_price_custom_title</Trans>
                                        </P.DeviceTitle>
                                    </P.DeviceHeader>
                                    <P.DeviceBottom>
                                        <P.DeviceSubtitle>
                                            <Trans>specific_price_custom_talk_to_experts</Trans>
                                        </P.DeviceSubtitle>
                                        <P.DeviceLink href={'mailto:sales@mobd.pl'}>
                                            <Trans>custom_contact</Trans>
                                        </P.DeviceLink>
                                    </P.DeviceBottom>
                                </P.Device>
                            }
                        </P.StyledCol>
                    </P.StyledRow>
                </Fade>
            </Container>
        </P.StyledSection>
    );
}

export default SectionPricing;