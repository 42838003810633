import styled from "styled-components";
import {Section} from "../../partials";
import {fonts, colors} from "../../../config";
import { GeneralTitle } from "../../generalStyles";

export const StyledSection = styled(Section)`
`;

export const StyledTitle = styled(GeneralTitle)`
  text-align: center;
  margin-bottom: 40px;
`;

export const StyledText = styled.p`
  margin: 0 auto 20px;
  font-family: ${fonts.nunitoSans};
  text-align: center;
  font-weight: 300;
  max-width: 915px;
  color: ${colors.white};
  line-height: 1.6;
  font-size: 16px;
`;

export const ImgWrapper = styled.div`
  min-height: 50vh;
  margin: 0 auto;

  @media screen and (max-width: 992px) {
    min-height: 25vh;
  }
  
  @media screen and (max-width: 576px) {
    display: none;
  }
`;

export const StyledImg = styled.img`
  width: 100%;
`;